@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*----------------------------------------------*
 General CSS
/*----------------------------------------------*/

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  outline: 0;
}

html,
body,
form,
fieldset,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-text-size-adjust: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  clear: both;
}

html {
  font-family: "Inter", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  /* font-size: 62.5%; */
}

body {
  background: #ffffff;
  font-family: "Inter", sans-serif;
}

a {
  background: transparent;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

a:active {
  outline: 0;
}

a:active,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

*:focus {
  outline: none;
  box-shadow: none;
}

.btn-close:focus {
  box-shadow: none;
}

b,
strong {
  font-weight: bold;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

video {
  width: 100%;
  height: 100%;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

mark {
  background: #ff0;
  color: #111111;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

ul,
ol {
  list-style: none outside none;
}

img {
  vertical-align: middle;
  border: 0;
  max-width: 100%;
}

ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

/*----new-myown-login--part------*/
.login-part-st {
  background-image: url(../images/Login_new.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.login-part-st .login-form {
  width: 400px;
  min-height: 420px;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  box-shadow: 0px 0px 10px rgba(241, 241, 241, 1);
  border-radius: 10px;
  text-align: center;
  padding-top: 55px;
  padding: 30px 34px;
  border: 1px solid #a9edff;
}

.login-part-st .muown-logo {
  width: 125px;
  height: auto;
  margin: 0 auto;
  padding-bottom: 12px;
}

.login-part-st .muown-logo img {
  width: 100%;
  height: auto;
}

.login-part-st h2 {
  font-weight: 700;
  font-size: 32px;
  color: #282828;
}

.mobile-inpt-pt .mbl-inpt-part {
  border-radius: 20px;
  padding-left: 50px;
  border: 1px solid #0d39a9;
}

.mobile-inpt-pt .cl-inpt-part-st {
  position: relative;
}

.mobile-inpt-pt .cl-inpt-part-st:after {
  position: absolute;
  top: 5px;
  left: 14px;
  content: "";
  /* background-image: url(../images/mobilre-pt.png); */
  background-image: url('data:image/svg+xml,<svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><rect width="16" height="24" fill="url(%23pattern0_414_935)"/><defs><pattern id="pattern0_414_935" patternContentUnits="objectBoundingBox" width="1" height="1"><use xlink:href="%23image0_414_935" transform="scale(0.0625 0.0416667)"/></pattern><image id="image0_414_935" width="16" height="24" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAYCAYAAADzoH0MAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjU4NTg5NjVCMThGMzExRUY5NDRDODc5QTYyRDkzMzNBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjU4NTg5NjVDMThGMzExRUY5NDRDODc5QTYyRDkzMzNBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NTg1ODk2NTkxOEYzMTFFRjk0NEM4NzlBNjJEOTMzM0EiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NTg1ODk2NUExOEYzMTFFRjk0NEM4NzlBNjJEOTMzM0EiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5QosiGAAAAq0lEQVR42mLktVzJgAQMgFiAAT/4AMQXYBwWKK0AxPOh9AMCBihAaUeQWhaowH4gbgTiBQzEgQKoHkNmdtnQBCDjB9QAYsEJkGYg1mACEvZAvJGBdLABFGZM0ED7QIYBID38TAwUglEDRg0YNQBhwAciijFsAKTnI8iAi0DsT4YBID0HQAZMAGIHaDFFLIiH6tnAAvWCI7SMyyeyUH2AXKgyQAUUySnWAQIMAGpEH+ra77ZRAAAAAElFTkSuQmCC"/></defs></svg>');
  width: 20px;
  height: 26px;
  background-size: contain;
  background-repeat: no-repeat;
}

.login-part-st .know-mr-btn a {
  color: #717171;
}

.login-part-st .know-mr-btn {
  display: flex;
  justify-content: flex-end;
  color: #717171;
}

.login-part-st .login-btn-usr {
  border: none;
  background: #0d39a9;
  width: 100%;
  padding: 8px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

.login-btn-usr-resend {
  border: none;
  background: #0d39a9;
  width: 50%;
  padding: 8px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

/*----new-myown-login--part------*/

/*------------otp-page-start--------------*/
.login-part-st .otp-part-pt {
  font-size: 40px;
  font-weight: 500;
  color: #000000;
}

.login-part-st .otp-text-pt {
  color: #606060;
}

.login-part-st .otp-clm-gap {
  column-gap: 15px;
  justify-content: center;
}

.login-part-st .otp-transpr-pt {
  width: 50px;
  height: 50px;
  text-align: center;
  border: 1px solid #0d39a9;
}

.login-part-st .resend-otp-fr-login p {
  color: #000000;
}

.login-part-st .resend-otp-fr-login span {
  color: #0d39a9;
}

/*----------otp-page-end-------------------------*/

/*---------register-form-part-start------------*/

.regstr-form .reg-frm-dta {
  display: flex;
  justify-content: space-between;
}

.regstr-form .lg-out-ft {
  border: 1px solid #0d39a9;
  border-radius: 104px;
  width: 120px;
  background: transparent;
  padding: 10px 40px;
  position: relative;
  color: #0d39a9;
  font-weight: 600;
}

.regstr-form .lg-out-ft:before {
  position: absolute;
  top: 15px;
  left: 15px;
  content: "";
  background-image: url(../images/log-out.svg);
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}

.reg-frm-dta {
  margin: 20px 0px 15px;
}

.regstr-form .reg-text-ft h2 {
  font-weight: 600;
  font-size: 32px;
  color: #282828;
}

.regstr-form .name-pt-ft {
  border: none;
  border-bottom: 1px solid #bbbbbb;
  border-radius: 0px;
}

.regstr-form .cls-gndr {
  width: 100%;
}

.regstr-form .ml-feml-itm {
  padding: 8px;
  background: transparent;
  border: none;
  border-bottom: 1px solid #bbbbbb;
  width: 100%;
}

.regstr-form .lvl-clr-hd {
  color: #777777;
}

.regstr-form .upld-prscrbtn-pt {
  width: 100%;
  border: 1px solid #0d39a9;
  padding: 10px;
  border-radius: 25px;
  background: transparent;
  color: #0d39a9;
  font-weight: 500;
}

.regstr-form .reg-knw-mrt a {
  color: #717171;
  text-decoration: underline;
}

.regstr-form .reg-text-ft h3 {
  color: #000000;
  font-weight: 600;
  font-size: 24px;
}

.regstr-form .use-lctn-part {
  border: 1px solid #0d39a9;
  border-radius: 30px;
  width: 170px;
  background: #0d39a9;
  padding: 10px 18px;
  position: relative;
  font-size: 12px;
  color: #ffffff;
  font-weight: 400;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.regstr-form .use-lctn-part:hover {
  border: 1px solid #0d39a9;
  background: transparent;
  color: #0d39a9;
}

/* .regstr-form .use-lctn-part:before {
  position: absolute;
  top: 12px;
  left: 15px;
  content: "";
  background-image: url(../images/crosshair.png);
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
} */
.use-lctn-part svg {
  width: 15px;
  height: 15px;
}

.regstr-form .end-bck-btn-pt {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
}

.regstr-form .end-bck-btn-pt .bck-btn-crcl {
  width: 120px;
  border: 1px solid #0d39a9;
  border-radius: 25px;
  background: transparent;
  padding: 7px;
  font-weight: 400;
  color: #0d39a9;
  font-size: 16px;
}

.regstr-form .next-btn-crcl {
  width: 160px;
  border-radius: 25px;
  background: transparent;
  color: #0d39a9;
  font-weight: 400;
  font-size: 16px;
  padding: 7px;
  border: 1px solid #0d39a9;
}

.regstr-form .next-btn-crcl:hover {
  border: 1px solid #0d39a9;
  background: #0d39a9;
  color: #fff;
}

.regstr-form .end-bck-btn-pt .bck-btn-crcl:hover {
  background: #0d39a9;
  color: #ffffff;
}

.regfrm-bag-image img {
  width: 80%;
  height: auto;
}

/*--------register-form-end-------------------------*/

/*----cart-page-start-----------*/
.cart-page-pt-add .reg-frm-dta-add {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.cart-page-pt-add .lg-out-ft {
  border: 1px solid #0d39a9;
  border-radius: 104px;
  width: 120px;
  background: transparent;
  padding: 10px 40px;
  position: relative;
  color: #0d39a9;
  font-weight: 600;
}

.cart-page-pt-add .lg-out-ft:before {
  position: absolute;
  top: 15px;
  left: 15px;
  content: "";
  background-image: url(../images/log-out.svg);
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}

.cart-item-pt .tab-content {
  display: none;
}

.cart-item-pt .tab-content.active {
  display: block;
}

/*------gallery-thumble----------*/
.cart-item-pt .fst-item {
  border: 1px solid #d6dade;
  height: 95px;
  padding: 2px 10px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-item-pt .item-design-medicn {
  display: flex;
  column-gap: 25px;
  margin-bottom: 35px;
}

.cart-item-pt .master,
.container .thumbnails {
  margin: auto;
  width: 100%;
  border: 2px solid #ccc;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.cart-item-pt .master {
  padding-bottom: 0;
  position: relative;
  padding: 30px;
}

.cart-item-pt .master img {
  z-index: 1;
  height: 99%;
}

.cart-item-pt .master .fa-chevron-left,
.cart-item-pt .master .fa-chevron-right {
  position: absolute;
  left: 5px;
  top: 5px;
  background-color: rgb(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px 15px;
  z-index: 2;
  cursor: pointer;
}

.cart-item-pt .master .fa-chevron-right {
  right: 5px;
  left: auto;
}

.cart-item-pt .thumbnails {
  overflow: hidden;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.cart-item-pt .thumbnails img {
  float: left;
  width: 19.2%;
  outline: 2px solid #bbb;
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 15px;
}

.cart-item-pt .thumbnails img:last-child {
  margin-right: 0 !important;
}

.cart-item-pt .thumbnails img.active {
  outline: 2px solid #0d39a9;
  opacity: 1;
  padding: 10px;
}

.medicne-details-pt {
  padding-left: 60px;
}

.medicne-details-pt p {
  font-weight: 700;
  font-size: 14px;
  color: #0d39a9;
}

.medicne-details-pt h3 {
  color: #1d2025;
  font-size: 34px;
  font-weight: 600;
}

.medicne-details-pt .pro-dtls-headr {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

.medicne-details-pt p {
  color: #0d39a9;
  font-weight: 500;
}

.price-details-pt .percntg-prt {
  background: #9db9c7;
  width: 50px;
  font-size: 20px;
  border-radius: 5px;
}

.price-details-pt h2 {
  font-size: 26px;
}

.cart-item-pt .price-details-pt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-item-pt .price-details-pt a {
  color: #717171;
}

.cart-item-pt .medicne-details-pt .cross-line {
  text-decoration: line-through;
}

.cart-item-pt .add-to-crt-part {
  display: flex;
  column-gap: 55px;
}

.cart-item-pt .plus-min-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 120px;
  background: #9db9c7;
  border-radius: 50px;
  padding: 10px 0px;
}

.cart-item-pt .minus-icon {
  border: none;
  background: transparent;
}

.medicne-details-pt .add-to-new-item {
  border: 1px solid #0d39a9;
  border-radius: 104px;
  width: 170px;
  background: #0d39a9;
  padding: 10px 30px;
  position: relative;
  font-size: 12px;
  color: #ffffff;
  font-weight: 400;
}

.medicne-details-pt .add-to-new-item::before {
  position: absolute;
  top: 12px;
  left: 30px;
  content: "";
  background-image: url(../images/add-to-cart.png);
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}

.medicne-details-pt .dumy-text-ft {
  color: #68707d;
  font-weight: 500;
  line-height: 26px;
  font-size: 15px;
}

.medicne-details-pt .Product-details {
  color: #bbbbbb;
  margin-top: 20px;
}

.medicne-details-pt .Product-details span {
  color: #0d39a9;
  border-bottom: 1px solid #e8e7e7;
  width: 100%;
  display: flex;
  padding: 5px;
}

.medicne-details-pt .list-grnd-item {
  padding: 0;
}

.medicne-details-pt .list-grnd-item li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e7e7;
  padding: 5px;
}

.medicne-details-pt .fst-grand-item {
  font-weight: bold;
}

.grant-item-part .fst-grand-item:nth-child(1) {
  color: red;
}

.master-gly-image .thumbnails img {
  width: 23% !important;
}

/*.injedtn-medicn-pt .injectn-image{ width:100px; height:auto; }
.injedtn-medicn-pt .injectn-image img{ width:100%; height:auto; }*/

/*--------------cart-page-end------------*/

/*-------order-information-part-start----------*/
.order-infrmtn-pt .ordr-info-part h2 {
  font-size: 32px;
  color: #1d2025;
  font-weight: 600;
}

.order-infrmtn-pt .ordr-info-part .ordr-fst-id {
  color: #777777;
  font-weight: 500;
  font-size: 18px;
}

.order-infrmtn-pt .price-details-pt .ordr-num {
  color: #000000;
  font-weight: 600;
  font-size: 18px;
}

.order-infrmtn-pt .price-details-dtls h5 {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}

.order-infrmtn-pt .price-details-dtls h4 {
  color: #68707d;
  font-weight: 600;
  font-size: 18px;
}

.order-infrmtn-pt .shipng-address h5 {
  color: #000000;
  font-weight: 600;
  font-size: 18px;
}

.order-infrmtn-pt .shipng-address h6 {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}

.order-infrmtn-pt .shipng-address p {
  color: #777777;
  font-size: 14px;
  font-weight: 400;
}

.order-infrmtn-pt .shipng-address .feedbk-wrt {
  font-family: "Raleway", sans-serif;
  width: 170px;
  border-radius: 20px;
  height: 40px;
  border: 1px solid #0d39a9;
  background: transparent;
  color: #0d39a9;
  font-weight: 600;
}

.order-infrmtn-pt .price-details-dtls .tablts-dtls {
  display: flex;
  justify-content: space-between;
}

.track-shipping-ft .track-point-pt h5 {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}

/* .order-infrmtn-pt .track-shipping-ft {
  padding-left: 35px;
} */

.track-shipping-ft .tracking-detail {
  padding: 3rem 0;
}

.track-shipping-ft #tracking {
  margin-bottom: 1rem;
}

[class*="tracking-status-"] p {
  margin: 0;
  font-size: 1.1rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

[class*="tracking-status-"] {
  padding: 1.6rem 0;
}

.track-shipping-ft .tracking-item {
  /* border-left: 4px solid #00ba0d; */
  position: relative;
  padding: 5px 20px;
  font-size: 0.9rem;
  min-height: 4rem;
}

.track-shipping-ft .tracking-item:last-child {
  padding-bottom: 0rem;
}

.track-shipping-ft .tracking-item::before {
  content: "";
  width: 4px;
  height: 100%;
  background: #dbdbdb;
  position: absolute;
  left: -5px;
  top: 18px;
}

.track-shipping-ft .tracking-item.current-tracking-item::before {
  background: #00ba0d;
}

.track-shipping-ft .tracking-item:last-child::before {
  content: "";
  display: none;
}

.track-shipping-ft .tracking-item .tracking-date {
  margin-bottom: 0.5rem;
}

.track-shipping-ft .tracking-item .tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}

.track-shipping-ft .tracking-item .tracking-content {
  padding: 0.6rem 0.6rem 0.6rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
}

.track-shipping-ft .tracking-item .tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}

.track-shipping-ft .tracking-item .tracking-icon {
  position: absolute;
  left: -0.7rem;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  background-color: #fff;
  color: #fff;
  top: 16px;
}

.track-shipping-ft .tracking-item-pending {
  border-left: 4px solid #d6d6d6;
  position: relative;
  padding: 2rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  margin-left: 3rem;
  min-height: 5rem;
}

.track-shipping-ft .tracking-item-pending:last-child {
  padding-bottom: 4rem;
}

.track-shipping-ft .tracking-item-pending .tracking-date {
  margin-bottom: 0.5rem;
}

.track-shipping-ft .tracking-item-pending .tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}

.track-shipping-ft .tracking-item-pending .tracking-content {
  padding: 0.5rem 0.8rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
}

.track-shipping-ft .tracking-item-pending .tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}

.track-shipping-ft .tracking-item-pending .tracking-icon {
  line-height: 2.6rem;
  position: absolute;
  left: -0.7rem;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  color: #d6d6d6;
}

.track-shipping-ft .tracking-item-pending .tracking-content {
  font-weight: 600;
  font-size: 17px;
}

.track-shipping-ft .tracking-item .tracking-icon.status-current {
  /* width: 1.9rem;
  height: 1.9rem;
  left: -1.1rem; */
  left: -0.7rem;
  width: 1.1rem;
  height: 1.1rem;
}

.track-shipping-ft .tracking-item .tracking-icon.status-intransit {
  color: #dbdbdb;
  font-size: 0.6rem;
}

.track-shipping-ft .tracking-item .tracking-icon.status-current {
  color: #00ba0d;
  font-size: 0.6rem;
}

.track-shipping-ft .dwn-btn-trk {
  display: flex;
  justify-content: space-between;
}

.track-shipping-ft .dwn-btn-trk .feedbk-wrt {
  font-family: "Raleway", sans-serif;
  width: 140px;
  border-radius: 20px;
  height: 40px;
  border: 1px solid #0d39a9;
  background: transparent;
  color: #0d39a9;
  font-weight: 600;
}

.track-shipping-ft .dwn-btn-trk .feedbk-wrt:hover {
  background: #0d39a9;
  border: 1px solid #0d39a9;
  color: #fff;
}

.dwn-btn-trk {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* @media (min-width: 992px) {
  .dwn-btn-trk {
    justify-content: flex-start;
  }
} */
.dwn-btn-trk .feedbk-wrt {
  font-family: "Raleway", sans-serif;
  width: 165px;
  border-radius: 20px;
  height: 40px;
  border: 1px solid #0d39a9;
  background: transparent;
  color: #0d39a9;
  font-weight: 600;
}

.dwn-btn-trk .knw-mr-track {
  color: #717171;
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .order-infrmtn-pt .track-shipping-ft {
    padding-left: 20px !important;
  }
}

@media (min-width: 992px) {
  .track-shipping-ft .tracking-item {
    margin-left: 0.5rem;
  }

  .track-shipping-ft .tracking-item .tracking-date {
    position: absolute;
    left: -10rem;
    width: 7.5rem;
    text-align: right;
  }

  .track-shipping-ft .tracking-item .tracking-date span {
    display: block;
  }

  .track-shipping-ft .tracking-item .tracking-content {
    padding: 12px 0 0;
    background-color: transparent;
  }

  .track-shipping-ft .tracking-item-pending {
    margin-left: 4rem;
  }

  .track-shipping-ft .tracking-item-pending .tracking-date {
    position: absolute;
    left: -10rem;
    width: 7.5rem;
    text-align: right;
  }

  .track-shipping-ft .tracking-item-pending .tracking-date span {
    display: block;
  }

  .track-shipping-ft .tracking-item-pending .tracking-content {
    padding: 0;
    background-color: transparent;
  }
}

.tracking-item .tracking-content {
  font-weight: 600;
  font-size: 17px;
}

.blinker {
  border: 7px solid #e9f8ea;
  animation: blink 1s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  50% {
    border-color: #fff;
  }
}

.order-infrmtn-pt h6 {
  line-height: 30px;
}

/*-----order-information-part-end----------------------*/

/*------feedback-part-start--------------*/
.feedback-form .fdbk-form-dtls h2 {
  font-size: 32px;
  color: #1d2025;
  font-weight: 600;
}

.write-review-pt .review-part-ft h2 {
  font-size: 26px;
  color: #1d2025;
  font-weight: 600;
}

.write-review-pt .emrok-injctn-frt {
  width: 80px;
  height: 80px;
  background: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.write-review-pt .emrok-pro-detls h5 {
  font-weight: 600;
  font-size: 16px;
  color: #1d2025;
}

.emrok-pro-detls .counterW {
  margin: 0 0 0 60px;
}

.emrok-pro-detls .ratingW {
  position: relative;
  margin: 10px 0 0;
  padding: 0;
}

.emrok-pro-detls .ratingW li {
  display: inline-block;
  margin: 0px;
}

.emrok-pro-detls .ratingW li a {
  display: block;
  position: relative;
  /*margin:0 3px;  width:28px; height:27px;color:#ccc; background:url('../img/ico/icoStarOff.png') no-repeat; background-size:100%;*/
}

/*.ratingW li.on a {background:url('../img/ico/icoStarOn.png') no-repeat; background-size:100%;}*/

.emrok-pro-detls .star {
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;
  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #ddd;
  border-left: 0.3em solid transparent;
  /* Controlls the size of the stars. */
  font-size: 15px;
}

.emrok-pro-detls .star:before,
.star:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 0.6em;
  left: -1em;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #ddd;
  border-left: 1em solid transparent;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
}

.emrok-pro-detls .star:after {
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}

.emrok-pro-detls .ratingW li.on .star {
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;
  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 0.3em solid transparent;
  /* Controlls the size of the stars. */
  font-size: 15px;
}

.ratingW li.on .star:before,
.ratingW li.on .star:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 0.6em;
  left: -1em;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 1em solid transparent;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
}

.emrok-pro-detls .ratingW li.on .star:after {
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}

.write-review-pt .emrok-pro-detls {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.write-review-pt .textarea-msg-submt textarea {
  width: 100%;
  background: #f5f5f5;
  border: none;
  padding: 10px;
  border-radius: 3px;
  height: 70px;
}

.foot-wse-btn-pt {
  display: flex;
  justify-content: space-between;
}

.back-to-main {
  /* background: transparent;
  width: 150px;
  height: 40px;
  border: 1px solid #0d39a9;
  color: #0d39a9;
  border-radius: 25px; */
  background: #0d39a9;
  color: #ffffff;
  width: 190px;
  height: 40px;
  border: 1px solid #0d39a9;
  border-radius: 25px;
}

.back-to-main a {
  text-decoration: none !important;
  color: white !important;
}

.write-review-pt .foot-wse-btn-pt .back-to-main:hover,
.write-review-pt .foot-wse-btn-pt .new-sbmt-off:hover,
.feedbk-wrt:hover {
  border: 1px solid #0d39a9;
  background: #0d39a9;
  color: #fff;
}

.new-sbmt-off {
  background: #0d39a9;
  color: #ffffff;
  width: 190px;
  height: 40px;
  border: 1px solid #0d39a9;
  border-radius: 25px;
}

.new-sbmt-off a {
  text-decoration: none !important;
  color: white !important;
}

.foot-wse-btn-pt {
  display: flex;
  column-gap: 15px;
  align-items: center;
}

@media (max-width: 767px) {
  .foot-wse-btn-pt {
    display: flex;
    column-gap: 15px;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 37px;
  }
}

.foot-wse-btn-pt a {
  color: #717171;
  text-decoration: underline;
}

.foot-wse-btn-pt .foot-info-fdbk {
  display: flex;
  column-gap: 25px;
}

/*-------feedback-form-end--------------------*/

/*------mr-landing-page-start-----------------*/
.landing-page-pt {
  /* padding-top: 45px; */
  background: #ffffff;
}

.landing-page-pt .landing-head-pt h2 {
  font-weight: 600;
  font-size: 32px;
  color: #282828;
}

.landing-page-pt .mr-landing-page {
  display: flex;
  justify-content: space-between;
}

.landing-page-pt .mbl-inpt-part {
  border-radius: 20px;
  padding-left: 57px;
  border: 1px solid #ffffff;
}

.landing-profl-detls {
  background: #ffffff;
  min-height: 100vh;
}

.landing-page-pt .cl-inpt-part-st:-ms-input-placeholder {
  color: red;
}

.landing-page-pt .cl-inpt-part-st {
  position: relative;
}

/* .landing-page-pt .cl-inpt-part-st::after {
  position: absolute;
  top: 10px;
  left: 14px;
  content: "";
  background-image: url(../images/bytesize_search.png);
  width: 20px;
  height: 26px;
  background-size: contain;
  background-repeat: no-repeat;
} */

.landing-page-pt .cl-inpt-part-st:before {
  position: absolute;
  top: 6px;
  left: 44px;
  content: "";
  background: #457b9d;
  width: 1px;
  height: 26px;
  background-size: contain;
  background-repeat: no-repeat;
}

.landing-page-pt .landing-scarch-box {
  width: 400px;
}

.landing-profl-detls .landing-form-dtls .tbl-chng-pt th {
  color: #5f6868;
  font-weight: 600;
  font-size: 15px;
}

.landing-profl-detls .landing-success-btn {
  background: #4bb543;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 5px;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
}

.landing-profl-detls .landing-pending-btn {
  background: #f26e6e;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 5px;
  font-size: 12px;
  font-weight: 400;
}

.landing-profl-detls .landing-form-dtls {
  background: #ffffff;
  box-shadow: 0px 0px 10px #eee;
  padding: 25px;
  border-radius: 14px;
  min-height: 400px;
}

.landing-profl-detls .dctr-pres-dwnld {
  background: #0d39a9;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  border: none;
  width: 120px;
  padding: 7px;
  border-radius: 16px;
}

.landing-profl-detls
  .landing-form-dtls
  table.dataTable.table-striped
  > tbody
  > tr.odd
  > * {
  background: #fef7e6;
  color: #737b7b;
  font-size: 14px;
}

.landing-profl-detls
  .landing-form-dtls
  table.dataTable.table-striped
  > tbody
  > tr.even
  > * {
  background: #fdefcc;
  color: #737b7b;
  font-size: 14px;
}

.landing-profl-detls #example p {
  line-height: 2;
  font-size: 12px;
}

.landing-profl-detls #example {
  vertical-align: middle;
}

.landing-profl-detls .landing-pending-btn {
  background: #f26e6e;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
}

.landing-profl-detls .landing-process {
  background: #0d39a9;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
}

.landing-profl-detls .landing-cancel {
  background: #fe5c5e;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
}

/*----------mr-landing-page-end-------------------*/

/* /////////////////////////////////////////////////////////////////////////////////////////////////// */

/* .btn-logout {
    border: 1px solid #0d39a9;
    border-radius: 104px;
    width: 120px;
    background: transparent;
    padding: 10px 40px;
    position: relative;
    color: #0d39a9;
    font-weight: 600;
}

.btn-logout::before {
    position: absolute;
    top: 15px;
    left: 15px;
    content: "";
    background-image: url(../images/log-out.png);
    width: 15px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
} */

.btn-logout {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border: 1px solid #0d39a9;
  width: 120px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 100%;
  background: transparent;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  color: #0d39a9;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.btn-logout:hover {
  background: #0d39a9;
  color: #fff;
}

.btn-logout svg {
  margin-right: 10px;
  stroke: #0d39a9;
}

.btn-logout:hover .logout-icon {
  stroke: #fff;
}

.sm-title {
  font-size: 0.875rem;
  color: #0d39a9;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 100%;
}

.md-title {
  font-size: 1.25rem;
  font-family: "Inter", sans-serif;
  color: #000000;
  font-weight: 600;
  line-height: 100%;
}

.lg-title {
  font-size: 2rem;
  font-family: "Inter", sans-serif;
  color: #1d2025;
  font-weight: 600;
  line-height: 100%;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-40 {
  margin-bottom: 40px;
}

.order-date-label {
  color: #68707d;
}

.order-date {
  color: #282828;
}

.product-dtls-content-box {
  display: flex;
  margin-bottom: 29px;
}

.product-dtls-image-content {
  background: #d9d9d9;
  padding: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.product-desc {
  margin-top: 1px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  color: #68707d;
  font-weight: 400;
}

.product-value {
  color: #1d2025;
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 10px;
}

.product-dtls-image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

/* .product-dtls-add-product {
  padding-left: 1.5rem;
  display: flex;
  justify-content: space-between;
  text-align: left;
  flex-direction: column;
} */

.product-dtls-add-product-title {
  margin-bottom: 0;
  font-size: 1.15rem;
  line-height: 100%;
  font-weight: 600;
  color: #1d2025;
}

.product-dtls-add-product h4 {
  color: #1d2025;
  font-weight: 600;
  font-size: 1.15rem;
  margin-top: 8px;
  line-height: 1.3;
}

.add-product-value {
  color: #1d2025;
  font-weight: 600;
  line-height: 100%;
}

.product-increement-decreement-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 120px;
  height: 34px;
  background: #9db9c7;
  color: #000000;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  border-radius: 20px;
  font-size: 1rem;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.increement-icon,
.decreement-icon {
  border: none;
  background: transparent;
}

.increement-icon svg,
.decreement-icon svg {
  fill: #0d39a9;
}

.mt-24 {
  margin-top: 1.5rem;
}

.mb-14 {
  margin-bottom: 14px;
}

.page-title-wrap {
  width: 100%;
}

.radio-btn-group {
  margin: 20px 0px;
}

.page-title-wrap .page-title {
  color: #1d2025;
  font-weight: 600;
  line-height: 100%;
  font-size: 1.8rem;
}

/* Custom radio btn group style start */

.radio-content [type="radio"]:checked,
.radio-content [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.radio-content [type="radio"]:checked + label,
.radio-content [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #000000;
  font-weight: 800;
  text-transform: uppercase;
}

.radio-content [type="radio"]:checked + label:before,
.radio-content [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border: 1px solid #cb2726;
  border-radius: 100%;
  background: #fff;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.radio-content [type="radio"]:checked + label:after,
.radio-content [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #cb2726;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  -o-transform: translateY(-50%) !important;
}

.radio-content [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio-content [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* .radio-btn-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
} */

.radio-content img {
  width: 100%;
  height: 100%;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .radio-content img {
    width: 70%;
  }
}

@media (min-width: 1700px) {
  .btn-logout {
    height: 51px;
  }

  .sm-title {
    font-size: 0.875rem;
  }

  .md-title {
    font-size: 1.5rem;
  }

  .lg-title {
    font-size: 2.625rem;
  }

  .product-dtls-add-product-title {
    font-size: 1.25rem;
  }

  .page-title-wrap .page-title {
    font-size: 2.625rem;
  }

  .radio-btn-group {
    justify-content: space-between;
  }

  .radio-btn-group {
    margin: 50px 0px;
  }

  .payment-dtls-form-title h6 {
    font-size: 1.5rem;
  }

  .payment-dtls-submit {
    height: 51px;
  }

  .payment-dtls-confirm {
    height: 51px;
  }

  .radio-content img {
    width: 100%;
    height: 100%;
  }
}

.payment-dtls-form-title h6 {
  color: #000000;
  font-weight: 600;
  font-size: 1.2rem;
}

/* .payment-details-form .form-control{

} */

.payment-details-form .form-label {
  color: #777777;
  font-weight: 500;
}

.payment-details-form .form-control {
  border-color: #bbbbbb;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-right: 0px;
  border-top: 0px;
  border-left: 0px;
}

.payment-dtls-submit {
  border: 1px solid #0d39a9;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  color: #0d39a9;
  margin-right: 30px;
  margin-top: 30px;
  height: 45px;
}

.payment-dtls-confirm {
  background: transparent;
  border: 1px solid #0d39a9;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  color: #0d39a9;
  margin-top: 34px;
  height: 45px;
}

.payment-dtls-submit:hover {
  background: #0d39a9;
  border: 1px solid #0d39a9;
  color: #fff;
}

.payment-dtls-confirm:hover {
  background: #0d39a9;
  border: 1px solid #0d39a9;
  color: #fff;
}

.form-control:focus {
  box-shadow: none;
}

.h-100 {
  height: 100%;
}

.modal-check-icon {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
}

.bg-primary {
  background-color: #0d39a9 !important;
  border: 1px solid #0d39a9 !important;
  color: #fff !important;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.bg-primary:hover {
  background-color: #fff !important;
  border: 1px solid #0d39a9 !important;
  color: #0d39a9 !important;
}

/* Custom radio btn group style end */

.notes-1 {
  color: #bbbbbb;
}

.notes-2 {
  color: #e2425c;
}

.notes-3 {
  color: #bbbbbb;
}

.kow-more-link {
  color: #717171;
}

.py-50 {
  padding: 50px 0px;
}

.text-color-1 {
  color: #68707d;
}

.order-details-list h5 {
  color: #68707d;
  font-weight: 600;
  line-height: 1.875rem;
  font-size: 1.1rem;
}

.order-details-list h6 {
  color: #1d2025;
  font-weight: 600;
  line-height: 1.875rem;
  font-size: 0.975rem;
}

@media (min-width: 992px) {
  .order-details-list h5 {
    font-size: 1.15rem;
  }

  .order-details-list h6 {
    font-size: 0.875rem;
  }
}

@media (min-width: 1700px) {
  .order-details-list h5 {
    font-size: 1.5rem;
  }

  .order-details-list h6 {
    font-size: 1.125rem;
  }

  .md-title {
    font-size: 1.5rem;
  }

  .logo-top-box {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .modal-payment-success {
    max-width: 529px;
  }
}

.text-underline {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 209px;
    display: inline-block;
    line-height: 23px;
  }

  .foot-info-fdbk {
    margin-bottom: 15px;
  }

  .foot-wse-btn-pt {
    flex-direction: column;
  }

  .landing-form-dtls h5 {
    margin-bottom: 15px;
  }

  .product-notes {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .md-title {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
  }

  .footer-copyright-text {
    text-align: center;
  }

  .privacy-policy-text {
    padding-top: 8px;
  }

  .page-title-wrap .page-title {
    font-size: 1.5rem;
  }

  .payment-dtls-form-title h6 {
    font-size: 1.1rem;
  }

  .payment-details-form .form-label {
    font-size: 0.9rem;
  }

  .radio-content img.credit-card-image {
    width: 70%;
  }

  .modal-check-icon {
    top: -33px;
  }

  .modal-check-icon svg {
    width: 65px;
    height: 65px;
  }

  .footer-bottom-btn-group {
    margin: auto;
  }

  .track-shipping-ft .dwn-btn-trk {
    justify-content: center;
  }

  .dwn-btn-trk {
    justify-content: space-between;
  }

  .track-shipping-ft .dwn-btn-trk .feedbk-wrt {
    margin-right: 15px;
  }

  .dwn-btn-trk .dwn-align-prft {
    margin-right: 15px;
  }

  .regstr-form,
  .checkout-cart-content,
  .place-order-container,
  .order-information-container,
  .write-review-pt,
  .feedback-form {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .product-image-col {
    width: 14.666667%;
  }
}

@media (min-width: 768px) {
  .modal-payment-success {
    max-width: 450px;
  }
}

.margin-list {
  margin-top: 2rem;
}

.kow-more-link:hover {
  color: #3f3f3f;
  text-decoration: underline;
}

.feedBackFromSelectDrop .select2-search {
  display: none;
}

.select2-container--default .select2-selection--single.feedBackFromSelect {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  height: 44px;
}

.select2-container--default
  .select2-selection--single.feedBackFromSelect
  .select2-selection__placeholder,
.select2-container--default
  .select2-selection--single.feedBackFromSelect
  .select2-selection__placeholder {
  color: #565656;
  font-family: "Inter", sans-serif;
}

.select2-container--default
  .select2-selection--single.feedBackFromSelect
  .select2-selection__arrow
  b,
.select2-container--default
  .select2-selection--single.feedBackFromSelect
  .select2-selection__arrow
  b {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L6 6.5L11 1.5' stroke='%23565656' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  border: none;
  background-repeat: no-repeat;
  height: 40px;
  width: 20px;
  left: auto;
  top: auto;
  margin-top: 0;
  background-position: center;
  display: flex;
  align-items: center;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  width: 100%;
  padding: 0.375rem 1.5rem 0.375rem 0.75rem;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #0d39a9;
}

/* Ratings star start */

.dataTables_paginate .active > .page-link,
.page-link.active {
  background-color: #0d39a9;
  border-color: #0d39a9;
}

.rating-group {
  display: inline-flex;
}

/* make hover effect work properly in IE */
.rating__icon {
  pointer-events: none;
}

/* hide radio inputs */
.rating__input {
  position: absolute !important;
  left: -9999px !important;
}

/* set icon padding and size */
.rating__label {
  cursor: pointer;
  /* if you change the left/right padding, update the margin-right property of .rating__label--half as well. */
  padding: 0 0.1em;
  font-size: 1rem;
}

/* add padding and positioning to half star labels */
.rating__label--half {
  padding-right: 0;
  margin-right: -0.6em;
  z-index: 2;
}

/* set default star color */
.rating__icon--star {
  color: orange;
}

/* set color of none icon when unchecked */
.rating__icon--none {
  color: #eee;
}

/* if none icon is checked, make it red */
.rating__input--none:checked + .rating__label .rating__icon--none {
  color: red;
}

/* if any input is checked, make its following siblings grey */
.rating__input:checked ~ .rating__label .rating__icon--star {
  color: #ddd;
}

/* make all stars orange on rating group hover */
.rating-group:hover .rating__label .rating__icon--star,
.rating-group:hover .rating__label--half .rating__icon--star {
  color: orange;
}

/* make hovered input's following siblings grey on hover */
.rating__input:hover ~ .rating__label .rating__icon--star,
.rating__input:hover ~ .rating__label--half .rating__icon--star {
  color: #ddd;
}

/* make none icon grey on rating group hover */
.rating-group:hover
  .rating__input--none:not(:hover)
  + .rating__label
  .rating__icon--none {
  color: #eee;
}

/* Ratings star end */

.logo-wrap {
  width: 90px;
}
@media (min-width: 992px) {
  .logo-wrap {
    width: 123px;
  }
}
.logo-wrap img {
  width: 100%;
  height: 100%;
}

.logo-top-box {
  margin-top: 25px;
  margin-bottom: 25px;
}

.footer-copyright-text {
  font-size: 0.938rem;
  color: #737b7b;
}

.privacy-policy-text {
  font-size: 0.938rem;
  line-height: 100%;
  color: #737b7b;
  text-decoration: underline;
}

.privacy-policy-text:hover {
  text-decoration: underline;
  color: #3f3f3f;
}

.footer {
  padding: 30px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.product-notes {
  margin-top: 40px;
  margin-bottom: 35px;
}

.product-notes p {
  line-height: 1.8;
  font-size: 0.875rem;
  font-weight: 400;
}

.know-more-text {
  margin-bottom: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 100%;
}

.know-mr-btn a {
  text-decoration: underline;
}

.know-mr-btn a:hover {
  text-decoration: underline;
  color: #3f3f3f;
}

.color-ligh-red {
  color: #e2425c !important;
}

.footer {
  /* margin-top: 40px; */
}

.payment-label-left-column p {
  line-height: 1.8;
}

.payment-label-right-column p {
  line-height: 1.8;
}

.payment-success-modal-footer h5 {
  line-height: 1.5;
  font-size: 1.027rem;
}

.payment-details-form .form-label,
.registration-form .form-label {
  margin-bottom: 0px;
}

.upld-prscrbtn-pt:hover {
  background-color: #0d39a9;
  border-color: #0d39a9;
  color: #fff;
}

/*----log-in-page-----------*/
@media screen and (max-width: 492px) {
  .login-part-st .login-form {
    width: 290px;
    padding: 22px;
    min-height: 0px;
  }

  .login-part-st .muown-logo {
    width: 95px;
  }

  .login-part-st h2 {
    font-size: 20px;
  }

  .login-part-st .muown-logo {
    padding-bottom: 11px;
  }

  .login-part-st .otp-part-pt {
    font-size: 20px;
  }

  .login-part-st .otp-transpr-pt {
    width: 40px;
    height: 40px;
  }

  .regstr-form .reg-text-ft h2 {
    font-size: 20px;
  }

  .regstr-form .lg-out-ft {
    width: 110px;
    padding: 5px 40px;
  }

  .regstr-form .lg-out-ft::before {
    top: 10px;
  }

  .regstr-form .my-locatn {
    text-align: center;
    margin-bottom: 20px;
  }

  .regstr-form .next-btn-crcl {
    width: 120px;
  }

  .regstr-form .lvl-clr-hd {
    margin-bottom: 0px;
  }

  .regstr-form .reg-text-ft h3 {
    font-size: 15px;
  }

  .reg-knw-mrt {
    width: 80px;
  }

  .regstr-form .reg-frm-dta {
    margin-bottom: 15px;
  }

  .regstr-form .reg-knw-mrt a {
    font-size: 14px;
  }

  .regstr-form .end-bck-btn-pt {
    justify-content: center;
  }

  .regstr-form .upld-prscrbtn-pt {
    padding: 5px;
  }

  .order-infrmtn-pt .ordr-info-part h2 {
    font-size: 20px;
  }

  .track-shipping-ft .dwn-btn-trk .feedbk-wrt {
    width: 120px;
  }

  .dwn-btn-trk .feedbk-wrt {
    font-size: 12px;
  }

  .dwn-btn-trk .knw-mr-track {
    text-decoration: underline;
  }

  .dwn-btn-trk .feedbk-wrt {
    width: 120px;
  }

  /* .dwn-align-prft {
    padding-left: 11px;
  } */
}

/*---------log-in-page-end----*/

.login-part-st h2 {
  /* font-size: 2rem; */
  font-size: 28px;
}

.login-part-st .otp-part-pt {
  font-size: 1.75rem;
  font-weight: 600;
}

/*------cart-image------------*/
.cart-image img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.emr-cart-page .cart-image-details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.emr-cart-page .cart-image-details h6 {
  font-size: 14px;
  font-weight: 700;
  color: #0d39a9;
}

.emr-cart-page .cart-image-details h2 {
  color: #1d2025;
  font-size: 32px;
  font-weight: 600;
}

.emr-cart-page .products-details {
  padding-top: 40px;
}

.emr-cart-page .products-details h5 {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
}

.emr-cart-page .products-details p {
  color: #68707d;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}

.emr-cart-page .cart-review-mr a {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  color: #717171;
}

.emr-cart-page .cart-image-details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.emr-cart-page .place-ordr-itm {
  background: #0d39a9;
  color: #ffffff;
  width: 190px;
  height: 40px;
  border: 1px solid #0d39a9;
  border-radius: 25px;
}

/*----------------cart-image-end---------*/

/* ///////////////////////////////////////////////////////////////////////////////////// */

/* New order information start */

/* .track-shipping-ft .tracking-item.pending {
  border-color: #cccccc;
} */
/* .track-shipping-ft .tracking-item.pending svg path {
  fill: #cccccc;
} */
.track-shipping-ft .tracking-item.pending {
  color: #52616b;
}

.track-shipping-ft .tracking-item.pending .tracking-content span {
  color: #52616b;
}

.order-info-bg-box {
  width: 100%;
  /* height: 506px; */
  height: 400px;
  /* overflow: hidden; */
}

.order-info-bg-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;
}

@media (max-width: 991px) {
  .track-shipping-ft {
    order: 2;
  }

  .ordr-info-part {
    order: 2;
    margin-top: 30px;
  }
}

/* New order information end */
.w-110 {
  width: 110px !important;
}

/*-----feedback-part-start---------*/

.review-start-pt .new-fdbc-part {
  width: 60px;
  height: 60px;
  background: #f5f5f5;
}

.review-start-pt .new-fdbc-part img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 5px;
}

@media screen and (max-width: 492px) {
  .back-to-main {
    width: 115px;
  }

  .new-sbmt-off {
    width: 115px;
  }

  .write-review-pt .review-part-ft h2 {
    font-size: 20px;
  }

  .lg-title {
    font-size: 22px;
  }

  .emr-cart-page .cart-image-details {
    margin-top: 25px;
  }
}

/*-----feedback-part-end---------*/
.regstr-form .blood-camp-pt {
  width: 100%;
  border-bottom: 1px solid #bbbbbb !important;
  border-radius: 0px;
  border: none;
  padding: 13px 0px;
  color: #777777;
}

.regstr-form .blood-camp-pt[type="date"]::-webkit-calendar-picker-indicator {
  background: none;
  position: absolute;
  right: 0;
  z-index: 1;
}

.regstr-form .blood-camp-pt[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  background: none;
  position: absolute;
  right: 0;
  z-index: 1;
}

.regstr-form .time-slot-icon,
.regstr-form .blood-camp-pt {
  position: relative;
}

.regstr-form .time-slot-icon::after {
  position: absolute;
  content: "\f017";
  top: 40px;
  right: 13px;
  font-family: "FontAwesome";
  font-size: 16px;
  display: inline-block;
  color: #777777;
  background-repeat: no-repeat;
  cursor: pointer;
}

.date-picker-icon::after {
  position: absolute;
  content: "\f133";
  top: 14px;
  right: 0;
  font-family: "FontAwesome";
  font-size: 16px;
  display: inline-block;
  color: #777777;
  background-repeat: no-repeat;
  cursor: pointer;
}

.dataTables_paginate {
  padding-bottom: 15px;
}

.bg-yellow {
  background: #0d39a9 !important;
  color: #fff !important;
}

resendMobOtp {
  background: #0d39a9 !important;
  color: #fff !important;
}

.slot-option {
  padding-top: 23px !important;
}

.requiredFld {
  color: red;
}

.nextLinkStyle {
  color: white;
}

.nextLinkStyle:hover {
  color: white;
}

.feedbackBtn {
  background: #0d39a9;
  color: #ffffff;
  width: 190px;
  height: 40px;
  border: 1px solid #0d39a9;
  border-radius: 25px;
}

.feedbackBtn a {
  color: white;
}

.star-rating {
  font-size: 24px;
}

.star {
  cursor: pointer;
  color: #ccc;
}

.star-filled {
  cursor: pointer;
  color: gold;
}

#paymentSuccessModal {
  pointer-events: none;
}

.landing-success-btn {
  background: #107409;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 5px;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
}

/*------mr-landing-page-start-----------------*/
.landing-page-pt {
  background: #ffffff;
}
/* @media (min-width: 992px) {
  .landing-page-pt {
    padding-top: 45px;
  }
} */
.landing-page-pt .landing-head-pt h2 {
  font-weight: 600;
  font-size: 32px;
  color: #282828;
}

.landing-page-pt .mr-landing-page {
  display: flex;
  justify-content: space-between;
}

.landing-page-pt .mbl-inpt-part {
  border-radius: 20px;
  padding-left: 57px;
  border: 1px solid #f1f1f1;
}

.landing-profl-detls {
  background: #ffffff;
  min-height: 100vh;
}

.landing-page-pt .cl-inpt-part-st:-ms-input-placeholder {
  color: red;
}

.landing-page-pt .cl-inpt-part-st {
  position: relative;
}

.landing-page-pt .cl-inpt-part-st:before {
  position: absolute;
  top: 6px;
  left: 44px;
  content: "";
  background: #457b9d;
  width: 1px;
  height: 26px;
  background-size: contain;
  background-repeat: no-repeat;
}

.landing-page-pt .landing-scarch-box {
  width: 400px;
}

.landing-profl-detls .landing-form-dtls .tbl-chng-pt th {
  color: #5f6868;
  font-weight: 600;
  font-size: 15px;
}

.landing-profl-detls .landing-success-btn {
  background: #4bb543;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 5px;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
}

.landing-profl-detls .landing-pending-btn {
  background: #f26e6e;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 5px;
  font-size: 12px;
  font-weight: 400;
}

.landing-profl-detls .landing-form-dtls {
  background: #ffffff;
  box-shadow: 0px 0px 10px #eee;
  padding: 25px;
  border-radius: 14px;
  min-height: 400px;
}

.landing-profl-detls .dctr-pres-dwnld {
  background: #0d39a9;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  border: none;
  width: 120px;
  padding: 7px;
  border-radius: 16px;
}

.landing-profl-detls
  .landing-form-dtls
  table.dataTable.table-striped
  > tbody
  > tr.odd
  > * {
  background: #fef7e6;
  color: #737b7b;
  font-size: 14px;
}

.landing-profl-detls
  .landing-form-dtls
  table.dataTable.table-striped
  > tbody
  > tr.even
  > * {
  background: #fdefcc;
  color: #737b7b;
  font-size: 14px;
}

.landing-profl-detls #example p {
  line-height: 2;
  font-size: 12px;
}

.landing-profl-detls #example {
  vertical-align: middle;
}

.landing-profl-detls .landing-pending-btn {
  background: #f26e6e;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
}

.landing-profl-detls .landing-process {
  background: #0d39a9;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
}

.landing-profl-detls .landing-cancel {
  background: #fe5c5e;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
}

/*----------mr-landing-page-end-------------------*/

.landing-form-dtls .landing-pending-btn {
  background: #f26e6e;
  width: 130px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
}

/* Track order history style start */
.track-order-btn {
  background: #0a4f73;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.track-order-btn a {
  text-decoration: none;
  text-align: center;
  color: #fff;
}

.btn-home {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border: 1px solid #0d39a9;
  width: 120px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 100%;
  background: transparent;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: #0d39a9;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.btn-home a {
  color: #0d39a9;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-home svg {
  stroke: #0d39a9;
  margin-right: 6px;
}

.btn-home:hover a {
  color: #fff;
}

.btn-home:hover {
  background: #0d39a9;
  color: #fff;
}

.btn-home:hover svg {
  stroke: #fff;
}

.checkboxstyle {
  width: 5px;
}

.consenttext {
  margin-left: 10px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-20 {
  margin-left: 3px;
}

.flotright {
  float: right;
}

.customer_caretext {
  font-size: 14px;
  margin-right: 16px;
  margin-top: 2px;
  text-decoration: none;
}

/* Common table style start */
.table-bordered-custom.table > :not(caption) > * > * {
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.table-bordered-custom.table th,
.table-bordered-custom.table td {
  text-align: center;
  vertical-align: middle;
}
.mt-32 {
  margin-top: 2rem;
}
/* Common table style end */

.floatright {
  float: right;
}

.messagestyle {
  font-size: 18px;
}

.auditrowstyle {
  margin-bottom: 10px;
  font-size: 14px;
}

.horizanlatrule {
  width: 50%;
  margin-top: 10px;
}

.auditdateheading {
  margin-top: 10px;
}
.marginright {
  margin-right: 5px;
}

.actionBtn {
  display: flex;
}

.rtobtn {
  margin-left: 5px;
}

table.dataTable.display > tbody > tr > td {
  white-space: nowrap;
}

table.dataTable thead > tr > th {
  white-space: nowrap;
  text-align: center;
}

.landing-clear-btn {
  background: #055aab;
  width: 120px;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  padding: 5px;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
}

.middleallignment {
  text-align: center;
}

/* .qr-download-btn a img {
  height: 20px;
} */
.error {
  color: red;
  font-size: 11px;
}

.common-table.dataTable > thead > tr > th,
.common-table.dataTable > thead > tr > td {
  border-bottom-color: #0d39a9 !important;
}

.common-table.dataTable > thead > tr > th {
  background-color: #f8f8f8;
  /* background-color: #f9f9f9; */
  /* border-color: #0d39a9; */
  color: #0d39a9;
}
table.common-table.dataTable.dataTable.stripe > tbody > tr.odd > *,
table.common-table.dataTable.dataTable.display > tbody > tr.odd > * {
  border-color: #0d39a9 !important;
}
table.common-table.dataTable.dataTable.stripe > tbody > tr.even > *,
table.common-table.dataTable.dataTable.display > tbody > tr.even > * {
  border-color: #0d39a9 !important;
}
table.common-table.dataTable.dataTable.cell-border > tbody > tr > th,
table.common-table.dataTable.dataTable.cell-border > tbody > tr > td {
  border-color: #0d39a9 !important;
}

table.common-table.dataTable.dataTable thead > tr > th:first-child.sorting {
  border-left: 1px solid #0d39a9 !important;
}

table.common-table.dataTable.dataTable thead > tr > th.sorting {
  border-top: 1px solid #0d39a9 !important;
  border-bottom: 1px solid #0d39a9 !important;
  border-right: 1px solid #0d39a9 !important;
}
table.common-table.dataTable.dataTable thead > tr > th {
  border-top: 1px solid #0d39a9 !important;
  border-bottom: 1px solid #0d39a9 !important;
  border-right: 1px solid #0d39a9 !important;
}
table.common-table.dataTable.dataTable thead > tr > th:first-child {
  border-left: 1px solid #0d39a9 !important;
}
table.common-table.dataTable.dataTable tbody > tr > td {
  text-align: center;
}
/* table.common-table.dataTable.no-footer {
  padding-top: 16px !important;
} */
.common-table.dataTable tr th {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
}
.common-table.dataTable tr td {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
}

table.common-table.dataTable.no-footer {
  border-color: #0d39a9 !important;
}

.dataTables_wrapper .dataTables_filter input {
  border: 1px solid #0d39a9 !important;
}
.info_msg {
  font-size: 13px;
  font-weight: 600;
}
/* table.common-table.dataTable.dataTable thead > tr > th:first-child.sorting, table.common-table.dataTable.dataTable tbody > tr > td:first-child{
  position: sticky;
  z-index: 1;
  background-color: #fff;
  left: 0;
  top: 0;
} */

.date-picker-wrap .react-datepicker-popper {
  z-index: 2;
}

.auditrowstyle div p {
  font-size: 14px;
}
/* Handle */
.dataTables_scroll .dataTables_scrollBody::-webkit-scrollbar-thumb {
  background: #062add;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

/* Handle on hover */
.dataTables_scroll .dataTables_scrollBody::-webkit-scrollbar-thumb:hover {
  background: #1934b9;
}

.order-details-table
  .common-fixed-coloumn-first
  thead
  > tr
  > th:first-child.sorting,
.order-details-table .common-fixed-coloumn-first tbody > tr > td:first-child {
  position: sticky;
  z-index: 1;
  background-color: #fff;
  left: 0;
  top: 0;
}

@media (max-width: 767px) {
  .ordr-info-part {
    margin-top: 0px;
  }
  .order-info-bg-box {
    height: 300px;
  }
  .track-shipping-ft .tracking-item::before {
    left: -4px;
  }
  .track-shipping-ft .tracking-item .tracking-icon {
    top: 13px;
  }
}
@media (max-width: 991px) {
  .col-order-track-side-banner {
    order: 3;
  }
  .tracking-item .tracking-content {
    font-size: 14px;
  }
}

.doc-landing-banner {
  width: 100%;
  height: 230px;
}
.doc-landing-banner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 767px) {
  .doc-landing-banner {
    margin-top: 32px;
  }
}

/* @media (max-width: 991px) { */
.dt-pick-container .react-datepicker-wrapper {
  width: 100%;
}
.dt-pick-container label {
  width: 100%;
}
.dt-pick-container input {
  width: 100%;
}
/* } */
@media (min-width: 769px) {
  .order-infrmtn-pt .track-shipping-ft {
    padding-left: 17px;
  }
}

.landing-success-btn.track-order-btn,
.landing-success-btn.gfc-btn,
.landing-success-btn.dispatch-btn {
  width: 25px;
  height: 25px;
  background-color: #0d39a9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  padding: 0;
  margin: auto;
}

.download-btn {
  width: 25px;
  height: 25px;
  background-color: #0d39a9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  padding: 0;
  margin: auto;
  color: #fff;
}
.download-btn:hover {
  color: #fff;
}
.landing-success-btn.gfc-btn svg {
  margin-left: 0.6px;
}
.regfrm-bag-image-wrap {
  margin-top: 16px;
  width: 100%;
  height: 300px;
}
.regfrm-bag-image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 991px) {
  .landing-form-dtls label,
  .landing-form-dtls .react-datepicker-wrapper {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .regfrm-bag-image-wrap {
    margin-top: 0;
  }
}
.btn-success {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

/* --sajal-01-4-24-- */
@media (max-width: 767px) {
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
  }
  .dataTables_wrapper .dataTables_info {
    text-align: left !important;
    padding-left: 16px;
  }
  .lvl-clr-hd {
    width: 100%;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .registration-form{
    margin-top: -19px !important;
  }
  .landing-clear-btn{
    margin-left: 5px;
    margin-top: 5px;
    
  }
  .mb_ad_doc{
    margin-bottom:10px;
  }
  .responsive_bulk_upload{
    justify-content: start !important;
  }

  .exportclass button{
    float: left !important;
  }
  .selectedfilename{
    padding-left: 0px !important;
  }
  .doqnload_qr_btn{
    margin-left: 19% !important;
  }
  .img_resize{
    margin-left: 10% !important;
  }
}

@media (max-width: 640px) {
  .landing-form-dtls div.dataTables_wrapper div.dataTables_length {
    text-align: left;
  }
  .landing-form-dtls div.dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
}

@media (max-width: 375px) {
  .landing-clear-btn {
    margin-left: 4px;
  }
  #dt-tbl_filter input {
    width: 174px !important;
  }
  .paginate_button.previous {
    font-size: 12px !important;
  }
  .paginate_button.next {
    font-size: 12px !important;
  }
}
/* .dataTables_scrollHeadInner,
.common-table,
.registration-form {
  width: 100% !important;
} */
.doqnload_qr_btn {
  position: absolute;
  width: 70px;
  cursor: pointer;
  /* top: 16%; */
  margin-left: 7%;
}

.qr_image{
  /* margin-top: -40px; */
}
/* .wraptext{
  width: 20px;
  word-wrap: break-word;
} */

.file-add-btn{
  order: 1;
  width: 90px;
}
.w-home-btn{
  width:180px !important;
}

.w-home-btn-doctor{
  width:152px !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-upload {
  border: 1px solid #0d39a9;
  color: #0d39a9;
  background-color: white;
  padding: 6px 47px 6px 20px;
  border-radius: 27px;
  font-size: 16px;
  height: 38px;
  font-weight: 600;
  overflow: hidden;
}

.upload-btn-wrapper input[type=file] {
  position: absolute;
  left: 0;
  width: 162px;
  height: 47px;
  top: 0;
  opacity: 0;
  display: inline-block;
  cursor: pointer;
}

.info-icon {
  /* border-left: 1px solid #0d39a9; */
  position: absolute;
  right: 7px;
  color: #0d39a9;
}

.info-icon::before {
  display: inline-block;
  content: "";
  height: 36px;
  position: absolute;
  right: 28px;
  top: -5px;
  width: 1px;
  background-color: #0d39a9;
}
.selectedfilename{
  float: left;
  /* margin-right: 50px; */
  margin-top: 5px;
  font-size: 13px;
  font-weight: 600;
  /* margin-left: 95px; */
  padding-left: 19px;
}
.break-series{
  max-width: 400px !important;
  white-space: normal;

}
.marginleft{
  margin-left: -27px !important;
}
.trackinfoImage{
  height: 80% !important;
}
.responsive_bulk_upload{
  justify-content: end;
}
.exportclass button{
  float: right;
}
.img_resize{
  margin-left: 20%;
  margin-top:15px;
}
.textleft{
  margin-left: 17px;
  margin-top: 15px;
  text-align: left;
  font-size: 14px;
}
.fontsizetext{
  font-size: 14px;
  text-align: left;
    margin-left: 17px;
}
.consentstyle{
  font-weight: 400;
}

div:where(.swal2-container) .swal2-html-container {
  overflow-x: hidden !important;
}
.privacytext ul li{
  margin-left: 40px;
  list-style-type: disc;
  font-size: 13px;
}
.privacytext p{
  margin-left: 20px;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 600;
}
.privacytext h4{
  font-size: 16px;
  margin: 10px;
  font-weight: 700;
}
.privacyblock{
  padding: 20px;
}
.main-content-privacy{
  margin-left: 60px;
}
.inactive_status{
  color: rgb(223, 10, 10);
  font-weight: 800px;
}
.active_status{
  color: rgb(34 88 3);
}
.assignjob{
  width: 16px;
}
.assignbtn{
  font-weight: 700px;
  color: black;
  margin-top:10px !important;
}

.canvasdiv{
  text-align: center;
}

.canvas-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure the container takes full height */
  position: relative; /* Ensure positioning context for absolute centering */
}

canvas {
  width: 100%; /* Make canvas fill its container width */
  max-width: 400px; /* Limit canvas width if needed */
  height: auto; /* Allow canvas to scale proportionally */
}

.download-qr-button {
  position: absolute;
  bottom: 10px; /* Adjust as needed for vertical position */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
}

.download_qr {
  /* width: 150px; */
  font-size: 14px;
  padding: 8px;
  text-align: center;
  /* position: absolute; */
  /* margin: 1px 0 15px 50px; */
}
.bulk-export{
  position: absolute;
  right: 45px;
}

.ms-12{
  margin-left:8px;
}

select option {
  width: 100%; /* Expand options to full width */
  /*white-space: nowrap;  Prevent wrapping */
  overflow: scroll; /* Hide overflow */
  /*text-overflow: ellipsis;  Show ellipsis for overflow text */
}
.addressbulkupload{
  margin-left: -36px;
}
.addressheading{
  font-size: 18px;
  font-weight: 800;
}
.doctorbulkupload{
  margin-left:14px;
}