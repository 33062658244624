@media (max-width: 767px) {
  .toggle-xs-sidebar {
    /* margin-left: -100%; */
    padding: 10px 0 20px 0;
  }
  .mobile-header-expand {
    left: 242px;
  }

  .layout-wrapper {
    padding-bottom: 57px;
    padding-top: 60px;
  }
  .privacy-policy-text {
    font-size: 0.58rem !important;
  }
  .footer-copyright-text {
    font-size: 0.53rem !important;
}
.footer--admin {
  padding: 8px calc(1.5rem * 0.5);
}
}
.main-content {
  margin-left: 242px;
}
@media (min-width: 768px) {
  /* .page-topbar {
    left: 242px;
  } */
  .expanded-topbar {
    left: 70px;
  }
  /* .main-content {
    margin-left: 242px;
  } */
  .btn-topbar {
    width: 42px;
  }
  .margin-left-remove {
    margin-left: 70px;
  }
  .page-top-bar-right-content {
    /* width: 61%; */
  }
  /* Small Sidebar menu show hover start  */
  .hover-expanded-menu .nav-item:hover .sidebar-nav-menu-box {
    position: absolute;
    width: calc(200px + 70px);
    background: var(--white);
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
    right: -270px;
    padding: 0.75rem;
  }
  .hover-expanded-menu
    .nav-item:hover
    .sidebar-nav-menu-box
    .sidebar-menu-text {
    display: block !important;
  }
  .hover-expanded-menu
    .nav-item:hover
    .dropdown-content.dropdown-content-collapse-mode {
    position: absolute;
    width: calc(200px + 70px);
    background: var(--white);
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
    right: -270px;
    padding: 0.75rem;
    display: block !important;
  }
  /* .footer--admin {
    left: 242px;
    height: 60px;
  } */
}
@media (max-width: 991px) {
  .navbar-header {
    justify-content: space-between;
    align-items: center;
    height: 70px;
    flex-direction: row;
    /* padding-left:100px; */
  }
  .page-top-bar-right-content {
    width: auto;
  }
  .sidebar-nav-menu-box{
    display: none;
  }
  .sidebar{
    width: 70px;
  }
  .main-content {
    margin-left: 80px;
}
.topnav-hamburger {
  display: none;
}
.logo-lg img{
  height: 20px;
}
}
@media (min-width: 992px) {
  .page-topbar-title {
    font-size: 1.762rem;
  }
  .navbar-header {
    justify-content: space-between;
    align-items: center;
    height: 70px;
    flex-direction: row;
  }
  .page-top-bar-right-content {
    width: 350px;
  }
  .search-container .form-control {
    width: 255px;
  }
  .topbar-user .btn {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .footer--admin {
    left: 242px;
    height: 60px;
  }
  .page-topbar {
    left: 242px;
}
}
@media (max-width: 991.99px) {
  .footer--admin {
    left: 70px;
  }
  .page-topbar {
    left: 70px;
}
}